import { makeObservable, observable, runInAction } from "mobx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TableCell, TDocumentDefinitions } from "pdfmake/interfaces";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

type Photo = {
  type: "Photo";
  key: string;
  group: string;
  getPng: () => Promise<string | undefined>;
};

type Table = {
  type: "Table";
  key: string;
  group: string;
  columns: string[];
  rows: TableCell[][];
};

/**
 * Store the statistics data in the local storage and generate a report when needed
 */
export class ReportGenerationStore {
  components: (Photo | Table)[];
  generatingReport: boolean;

  constructor() {
    makeObservable(this, {
      generatingReport: observable
    });
    this.components = [];
    this.generatingReport = false;
  }

  // To get how many photo/table are stored in the local storage
  get numberOfComponents() {
    return this.components.length;
  }

  // To add a photo/table to the local storage
  addComponent = (component: Photo | Table) => {
    this.deleteComponent(component.key);
    this.components.push(component);
  };

  // Delete a photo/table in the local storage
  deleteComponent = (key: string) => {
    this.components = this.components.filter((component: Photo | Table) => component.key !== key);
  };

  // Rearranging the order of the photo/table in the loacl storage
  swapComponents = (from: number, to: number) => {
    const [removed] = this.components.splice(from, 1);
    this.components.splice(to, 0, removed);
  };

  // To generate a report based on the photo/table that are stored in the local storage
  generateReport = async (reportName: string) => {
    if (this.components.length === 0) {
      throw new Error("Please add some components into the report!");
    }

    runInAction(() => {
      this.generatingReport = true;
    });

    // Add the title of the report
    const content: Content = [
      {
        text: "LexX Technologies Report",
        style: "title"
      }
    ];

    // Add the photo/table to the report
    for (const component of this.components) {
      const { type } = component;
      switch (type) {
        case "Photo":
          const { key: photoKey, getPng, group: groupName } = component;
          content.push({
            text: photoKey,
            style: "sectionTitle"
          });
          content.push({
            table: {
              headerRows: 1,
              widths: ["*"],
              body: [
                [
                  {
                    image: await getPng(),
                    width: 500,
                    height: 400,
                    style: {
                      alignment: "center"
                    }
                  }
                ]
              ]
            },
            pageBreak: 'after',
            style: "marginBottomForPhoto"
          });
          for (const tableComponent of this.components) {
            const { type } = tableComponent;
            if(type === "Table" ) {
              const { key: tableKey, columns, rows, group: tableGroupName } = tableComponent;
              if(groupName === tableGroupName) {
                content.push({
                  text: tableKey,
                  style: "sectionTitle"
                });
                content.push({
                  table: {
                    headerRows: 1,
                    heights: 600 / 20,
                    widths: Array(columns.length).fill("*"),
                    body: [[...columns], ...rows]
                  },
                  style: "marginBottomForTable",
                  pageBreak: 'after'
                });
              }
            }
          }

          break;
      }
    }

    // Format the report
    const docDefinition: TDocumentDefinitions = {
      content: content,
      styles: {
        title: {
          fontSize: 25,
          bold: true,
          alignment: "center",
          margin: [0, 370, 0, 370]
        },
        marginBottomForPhoto: {
          margin: [0, 0, 0, 150]
        },
        marginBottomForTable: {
          margin: [0, 0, 0, 50]
        },
        sectionTitle: {
          fontSize: 20,
          bold: true,
          margin: [0, 0, 0, 20]
        }
      }
    };

    runInAction(() => {
      this.generatingReport = false;
    });

    pdfMake.createPdf(docDefinition).download(reportName);
  };
}
