import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useNumberOfProceduresByMonth } from "../../../hooks/StatisticsHooks";
import { useNumberOfPartsBreakdownByMonth } from "../../../hooks/StatisticsHooks";
import { useNumberOfWorkOrderByMonth } from "../../../hooks/StatisticsHooks";

/**
 * To draw number of information lookups by month bar chart
 */
export const NumberOfInformationLookupsByMonthVisualization = ({ index, startDate, endDate }) => {
  const { selectInDateRange: selectInDateRangeForNumberOfProceduresByMonth } = useNumberOfProceduresByMonth();
  const { selectInDateRange: selectInDateRangeForNumberOfPartsBreakdownByMonth } = useNumberOfPartsBreakdownByMonth();
  const { selectInDateRange: selectInDateRangeForNumberOfWorkOrderByMonth } = useNumberOfWorkOrderByMonth();

  // Retrieve the data
  selectInDateRangeForNumberOfProceduresByMonth(startDate, endDate);
  selectInDateRangeForNumberOfPartsBreakdownByMonth(startDate, endDate);
  selectInDateRangeForNumberOfWorkOrderByMonth(startDate, endDate);
  const { data: numberOfProceduresByMonthData } = useNumberOfProceduresByMonth(startDate, endDate);
  const { data: numberOfPartsBreakdownByMonth } = useNumberOfPartsBreakdownByMonth(startDate, endDate);
  const { data: numberOfWorkOrderByMonth } = useNumberOfWorkOrderByMonth(startDate, endDate);

  return (
    <ResponsiveContainer width="100%" height={600} margin={{ right: 50 }}>
      <BarChart
        data={
          index == 0
            ? numberOfProceduresByMonthData
            : index == 1
            ? numberOfPartsBreakdownByMonth
            : numberOfWorkOrderByMonth
        }
        margin={{ top: 20, right: 50 }}
      >
        <Legend verticalAlign="top" height={50} iconSize={30} />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="mmYYYY" name="mmYYYY" />
        <YAxis dataKey="numberOfTimes" name="numberOfTimes" />
        <Tooltip />
        <Legend />
        <Bar dataKey="numberOfTimes" fill="#5182BB" name="Number of times" />
      </BarChart>
    </ResponsiveContainer>
  );
};
