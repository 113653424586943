import { Modal, Table, Tag } from "antd";
import React, { useState } from "react";
import { useBoolean } from "usehooks-ts";
import { useTopNlSearches } from "../../../hooks/StatisticsHooks";
import { VisualizationType } from "../../../consts/VisualizationType";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList
} from "recharts";

/**
 * To draw the queries per user table
 */
 export const TopNlSearchesTableGraph = ({ startDate, endDate, visualizationType, visualizationRef }) => {
    const { data, loading } = useTopNlSearches();
    const [selectedNlSearch, setSelectedNlSearch] = useState(null);
    const { value: modalVisible, setTrue: showModal, setFalse: hideModal } = useBoolean();
  
    const onNlSearchClick = (nlSearch) => {
      setSelectedNlSearch(nlSearch);
      showModal();
    };

  const onUsernameTagClick = (username) => {
    setSelectedUsername(username);
    showModal();
  };

  const dataSet = [];
  data?.sort((a, b) => a.numberOfTimes < b.numberOfTimes ? 1 : -1)
  data?.slice(0,10).forEach((d)=> {
    dataSet.push({nlSearch: d.nlSearch, 
        numberOfTimes: d.numberOfTimes,
                percentage: d.percentage});
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const values = [];
      dataSet?.forEach((item)=> {
        if(label === item.nlSearch){
          values.push({
            nlSearch: item.nlSearch,
            numberOfTimes: item.numberOfTimes,
            percentage: item.percentage
          });
        }
      })
      return (
        <div className="custom-tooltip">
          <p className="label">Query : {`${values[0].nlSearch}`}</p>
          <p className="intro">Number of searches : {`${values[0].numberOfTimes}`}</p>
          <p className="desc">% of Total Count of Query : {`${values[0].percentage}`}</p>
        </div>
      );
    }
  
    return null;
  };

  const formatYAxis = (label) => {
    if(label.length > 15){
      return label.slice(0,12)+"...";
    }
    return label;
  }

  let chart;
  switch (visualizationType) {
    case VisualizationType.BAR:
      chart = (
        <BarChart data={dataSet} 
                  ref={visualizationRef} 
                  margin={{ top: 20, left: 50, right: 50}} 
                  layout="vertical"
                  
                  >
          <Legend verticalAlign="top" height={50} iconSize={20} />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" 
          />
          <YAxis type="category" dataKey="nlSearch" tickFormatter={formatYAxis}/>
          <Tooltip content={<CustomTooltip />}/>
          <Legend />
          <Bar isAnimationActive={false} dataKey="numberOfTimes"  fill="#5182BB" name="Searches per query" >
            <LabelList dataKey="numberOfTimes" position="right" style={{fontSize:"10px"}}/>
          </Bar>
        </BarChart>
      );
      
      break;
    case VisualizationType.LINE:
    default:
      break;
  }

  return (
    <>
      <div style={{ backgroundColor: "white" }}>
      <ResponsiveContainer width="100%" height={500} margin={{ right: 50 }} scale>
        {chart}
      </ResponsiveContainer>
    </div>
    </>
  );
};
