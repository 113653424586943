import { AreaChartOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Col, DatePicker, Dropdown, Menu, Row, Space, Typography } from "antd";
import React from "react";
import { useHelperToolbarStyles } from "./styles";

const { Text } = Typography;
const { RangePicker } = DatePicker;

/**
 * Helper toolbar for seleting the date range
 */
export const HelperToolbar = ({
  title,
  visualizationTypeOptions = [],
  onDateRangeChange,
  onVisualizationTypeSelect,
  onFullscreenIconClick
}) => {
  const { toolbarCls, iconCls } = useHelperToolbarStyles();

  return (
    <Row className={toolbarCls} justify="space-between" align="middle">
      <Col>
        <Row align="middle">
          <Space direction="horizontal">
            <Text>{title}</Text>
          </Space>
        </Row>
        <Row align="middle">
          <RangePicker
            onChange={(dates, dateStrings) => {
              const [start, end] = dateStrings;
              onDateRangeChange(start, end);
            }}
          />
          {visualizationTypeOptions.length > 0 && (
            <Dropdown
              overlay={
                <Menu>
                  {visualizationTypeOptions.map((availableVisualizationType, key) => (
                    <Menu.Item
                      key={key}
                      onClick={() => {
                        if (onVisualizationTypeSelect) {
                          onVisualizationTypeSelect(availableVisualizationType);
                        }
                      }}
                    >
                      {availableVisualizationType}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={["click"]}
            >
              <AreaChartOutlined className={iconCls} style={{ marginLeft: "5px" }} />
            </Dropdown>
          )}
          {onFullscreenIconClick && (
            <FullscreenOutlined className={iconCls} style={{ marginLeft: "5px" }} onClick={onFullscreenIconClick} />
          )}
        </Row>
      </Col>
    </Row>
  );
};
