import { Button, Col, Divider, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useBoolean } from "usehooks-ts";
import { MonthlyQueriesSection } from "../../organisms/MonthlyQueriesSection/MonthlyQueriesSection";
import { QueriesPerUserSection } from "../../organisms/QueriesPerUserSection/QueriesPerUserSection";
import ReportGenerationModal from "../../organisms/ReportGenerationModal/ReportGenerationModal";
import SideNavigationMenu from "../../organisms/SideNavigationMenu/SideNavigationMenu";
import TopMenuNavigation from "../../organisms/TopMenuNavigation/TopMenuNavigation";
import { TopNlSearchesSection } from "../../organisms/TopNlSearchesSection/TopNlSearchesSection";
import { useDashboardPageStyles } from "./styles";
import { InformationLookupsSection } from "../../organisms/InformationLookupsSection/InformationLookupsSection";
import { EquipmentSection } from "../../organisms/EquipmentSection/EquipmentSection";
import { DocumentTypeSection } from "../../organisms/DocumentTypeSection/DocumentTypeSection";
import { statisticsService } from "../../../services/StatisticsService";


const { Content } = Layout;
/**
 * The dashboard (usage report) page
 */
const DashboardPage = () => {
  const { value: modalVisible, setTrue: showModal, setFalse: hideModal } = useBoolean(false);
  const [signedUrl, setSignedUrl] = useState(null);

  const { mainContentCls } = useDashboardPageStyles();

  useEffect(async() => {
    const fetchSignedUrl = await statisticsService.getLookerUrl();
    setSignedUrl(fetchSignedUrl.getSignedLookerUrl);
  }, []);

  return (
    <Layout style={{ minHeight: "100vh", maxHeight: "3420px" }}>
      <TopMenuNavigation />
      <Layout>
        <SideNavigationMenu />
        <Content className={mainContentCls}>
          {/* <Row align="middle" justify="end">
            <Button type="primary" onClick={showModal}>
              Generate report
            </Button>
          </Row> */}
          <Row>
          {
            signedUrl !== null && <iframe
              width="100%"
              height="3350px"
              src={signedUrl}
              style={{ border: "none" }}
              allowFullScreen
            ></iframe>
          }
           <div style={{
                      position: "fixed",  // Change to fixed to stay at the bottom
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "22px",  // Adjust this height to cover the footer
                      backgroundColor: "#f0f2f5",
                      zIndex: 1  // Ensure it stays above the iframe content
                    }}>
          </div>
          </Row>
          {/* <Divider /> */}
          {/* <Row justify="center" gutter={[30, 60]}>
            <Col span={12}>
              <QueriesPerUserSection />
            </Col> 
            <Col span={12}>
              <MonthlyQueriesSection />
            </Col>
            <Col span={12}>
              <TopNlSearchesSection />
            </Col>             
            <Col span={12}>
              <EquipmentSection/>
            </Col>
            <Col span={12} style={{marginRight: 'auto'}}>
              <DocumentTypeSection/>
            </Col>
          </Row> */}
        </Content>
      </Layout>
      <ReportGenerationModal visible={modalVisible} onCancel={hideModal} />
    </Layout>
  );
};
export default DashboardPage;